import "./src/css/style.css";

if (
  typeof window !== "undefined" &&
  !document.getElementById("gumroad-script")
) {
  const script = document.createElement("script");
  script.defer = "true";
  script.id = "gumroad-script";
  script.src = "https://gumroad.com/js/gumroad.js";
  document.head.append(script);
}
